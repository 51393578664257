
import YouTube from "react-youtube";
import {useSubtitlePlayer} from "../../SubtitleContext"
const VideoViewer = ({url}) => {
    console.log("viewer호출")

    const opts = {
      width: "640",
      height: "390",
      playerVars: {
        autoplay: 1,
      },
    };
    const playerRef = useSubtitlePlayer();
    const onReady = (e) => {

      
      playerRef.current = e.target
      console.log(playerRef)
    }

    return (
      url !== "" ? <YouTube videoId={url} opts={opts} onReady={onReady}/> : <div class='NullvideoArea'></div>
    )
  }

  export default VideoViewer;