import {useState, useMemo, useRef} from "react";
import {
  Item,
  Input,
  Label,
  Header,
  Icon,
  Segment
} from "semantic-ui-react";
import { useSubtitleDispatch, useSubtitleState, useSubtitlePlayer } from "../SubtitleContext";
import React from 'react'

const MapRendering = ({ data, dispatch }) => {
  const subtitleData = useMemo(() => data, [data])
  const player = useSubtitlePlayer()
  const [selected, setSelected] = useState(false)
  const ref = useRef()
  const changeTime = (e) => {

    if (player.current !== undefined){
    player.current.seekTo(e.target.getAttribute('data-starttime'))
    player.current.playVideo();
    console.log("changeTime")
    }
  }
  const timeFormatChange = (data) => {
    let Hour = parseInt(data / 3600);
    let Min = parseInt((data % 3600) / 60);
    let Sec = parseInt((data % 3600) % 60);
    let Mill = parseInt((((data % 3600) % 60) - Sec) * 1000);
  
    Hour = Hour < 10 ? "0" + Hour : String(Hour);
    Min = Min < 10 ? "0" + Min : String(Min);
    Sec = Sec < 10 ? "0" + Sec : String(Sec);
    Mill =
      Mill < 10
        ? "00" + Mill
        : Mill < 100
        ? "0" + Mill
        : String(Mill);
  

    let time = `${Hour}:${Min}:${Sec},${Mill}`;
    return time;
  }

  const onFocus = (e) => {
    setSelected(true)
    console.log(e)
    ref.current.focus()

  }

  const InputArea = ({ data }) => {
    const [korean, setKorean] = useState(data.korean);
    const onChange = (e) => {
      setKorean(e.target.value)
    }

    const onBlur = (event) => {
      // console.log(event.target.parentNode.getAttribute('data-id'));
      let id_key = event.target.parentNode.getAttribute('data-id');
      // console.log(event.target.value)
      // let data = subtitle.find(e => e.id === parseInt(id))
      dispatch({
          type:"UPDATE",
          id: id_key,
          korean: event.target.value
          
      })
      let storageData = JSON.parse(localStorage.getItem(`subtitle_${id_key}`))
      storageData.korean = event.target.value
      localStorage.setItem(`subtitle_${id_key}`, JSON.stringify(storageData))

    } 
    return (
      <Input fluid  data-id={data.id} >
      <input type="text"  onBlur={onBlur} defaultValue={data.korean} ></input>
  </Input>
    )
  }

    return (
      <>
      <Segment size="large" className="subtitleArea" >
        <Item>
          <Item.Content>
            <Item.Meta data-starttime={subtitleData.start} onClick={changeTime}>
              <Label>{subtitleData.id}</Label> {timeFormatChange(subtitleData.start)} ~ {timeFormatChange(subtitleData.end)}
            </Item.Meta>
            <Item.Header content={subtitleData.text} />
            <Item.Extra>
          
              <InputArea data={data}></InputArea>
            
            </Item.Extra>
          </Item.Content>
        </Item>
        </Segment>
      </>
    );
  }


  const MapPlaceHolder = () => {
    return (
      <div className="subtitle_default">
        <Header as="h2" icon>
          <Icon name="settings" />
          자막 파일 미선택
          <Header.Subheader>자막 파일을 선택해주세요.</Header.Subheader>
        </Header>
      </div>
    );
  };
  // const MapRenderingMemo = React.memo(MapRendering, (prevProps, nextProps) => {
  //   console.log(prevProps)
  //   if (prevProps.updatedAt === nextProps.updatedAt) {
  //     return true;
  //   }
  //   console.log(prevProps)
  //   return false;
  // })
  const MapRenderingMemo = React.memo(MapRendering, (prevProps, nextProps) => {

    if (prevProps.updatedAt === nextProps.updatedAt) {
      return true;
    }

    return false;
  })
const SrtToMap = ({player, youtubeUrl}) => {

  const subtitle = useSubtitleState();
  const dispatch = useSubtitleDispatch();


  return (
    <div>
      {subtitle.length === 0 ? <MapPlaceHolder></MapPlaceHolder> : ""}
      <Item.Group>
        {subtitle.map((e) => (
          <MapRenderingMemo data={e} key={e.id} dispatch={dispatch} ></MapRenderingMemo>
        ))}
      </Item.Group>
    </div>
  );
};

export default SrtToMap