import "semantic-ui-css/semantic.min.css";

import React, { useEffect } from 'react'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'
import { useSubtitleDispatch } from "../../SubtitleContext";

function LocalstorageCheckModal({setFileAdd}) {
  const [open, setOpen] = React.useState(false)
  const dispatch = useSubtitleDispatch();


  const onCancel = (e) => {
      localStorage.clear();
      setOpen(false)
      
  }


  const onConfirm = (e) => {
      let length = localStorage.length;
      for (let i=1; i < length + 1; i++) {
            let data = JSON.parse(localStorage.getItem(`subtitle_${i}`))
            dispatch({
              type: "CREATE",
              subtitle: {
                id: data.id,
                start: data.start,
                end: data.end,
                text: data.text,
                korean: data.korean,
              }
            })
           
      }
      setOpen(false)
      setFileAdd(true)
  }

  useEffect(() => {
      let chk = localStorage.length
      if (chk > 0) {
          setOpen(true)
      }
  }, [])
  return (
    <Modal
      basic
      // onClose={() => setOpen(false)}
      // onOpen={() => setOpen(true)}
      open={open}
      size='small'
      trigger={<Button>Basic Modal</Button>}
    >
      <Header icon>
        <Icon name='archive' />
        기존에 등록되어있는 자막이 있습니다.
      </Header>
      <Modal.Content>
        <p style={{textAlign:"center"}}>
          기존에 작업하던 자막이 있습니다. 불러오시겠습니까?
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color='red' inverted onClick={onCancel}>
          <Icon name='remove' /> 기존 작업 내용 삭제하기
        </Button>
        <Button color='green' inverted onClick={onConfirm}>
          <Icon name='checkmark' /> 불러오기
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default LocalstorageCheckModal