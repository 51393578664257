import { useRef, useState } from "react";
import {
  Input,
  Form,
  Button,
  Message,
} from "semantic-ui-react";
import { useSubtitleDispatch } from "../../SubtitleContext";
import parseSRT from "parse-srt";
import React from 'react'
const SubtitleCreate = ( {fileAdd, setFileAdd} ) =>  {
  const dispatch = useSubtitleDispatch();
  const [fileData, setFileData] = useState();
  const [noFileMessage, setNoFileMessage] = useState(false)
  const ref = useRef();
  const button = useRef();

  function onSubmit() {
    if(fileData !== undefined){
      setNoFileMessage(false)
    let reader = new FileReader();
    reader.onload = function () {
      parseSRT(reader.result).map((e) => {
        dispatch({
          type: "CREATE",
          subtitle: {
            id: e.id,
            start: e.start,
            end: e.end,
            text: e.text,
            korean: e.text,
          }
        })

        localStorage.setItem(`subtitle_${e.id}`, JSON.stringify({
          id: e.id,
          start: e.start,
          end: e.end,
          text: e.text,
          korean: e.text,
        }))
      }
      );

    };
    reader.readAsText(fileData, "euc-kr");
    setFileAdd(true)

  } else {
    setNoFileMessage(true)
  }
  }
  const filechange = (e) => {
    let file = e.target.files[0];
    setFileData(file);

  };

  // console.log(useSubtitleState)

  return (
    <>
    
    <Form.Field>
      <label>자막 파일</label>
      <Input button>
        <input type="file" ref={ref} onChange={filechange}></input>
        <Button ref={button} disabled={fileAdd} onClick={onSubmit}>{fileAdd? "등록완료" : "자막 등록하기"}</Button>
      </Input>
    </Form.Field>
      {noFileMessage ?     <Message negative>
    <Message.Header>파일이 선택되지 않았습니다.</Message.Header>
  </Message> : ""}

    </>
  );
}

export default SubtitleCreate;
