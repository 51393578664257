import { useReducer, createContext, useContext, useRef } from "react";
const initialState = []

function reducer(state, action) {
  switch (action.type) {
    case 'CREATE':
      return state.concat(action.subtitle);
    case 'UPDATE':
      return state.map(subtitle => subtitle.id === parseInt(action.id)? ({...subtitle, korean: action.korean}) : subtitle)
    case 'REMOVE':
      return state.filter(subtitle => subtitle.id !== action.id);
    case 'ERASE':
        return state.filter(subtitle => subtitle.id === 0);
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

const SubtitleStateContext = createContext();
const SubtitleDispatchContext = createContext();
const SubtitlePlayerContext = createContext();
export function SubtitleProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const player = useRef();
  
    return (
      <SubtitleStateContext.Provider value={state}>
        <SubtitleDispatchContext.Provider value={dispatch}>
          <SubtitlePlayerContext.Provider value={player}>
          {children}
          </SubtitlePlayerContext.Provider>
            
        </SubtitleDispatchContext.Provider>
      </SubtitleStateContext.Provider>
    );
  }
  
  export function useSubtitleState() {
    const context = useContext(SubtitleStateContext);
    if (!context) {
      throw new Error('Cannot find SubtitleProvider');
    }
    return context;
  }
  
  export function useSubtitleDispatch() {
    const context = useContext(SubtitleDispatchContext);
    if (!context) {
      throw new Error('Cannot find SubtitleProvider');
    }
    return context;
  }
  
  export function useSubtitlePlayer() {
    const context = useContext(SubtitlePlayerContext);
    if (!context) {
      throw new Error('Cannot find SubtitlePlayer');
    }
    return context;
  }
  
