import "./App.css";
import { useRef, useState } from "react";

import "semantic-ui-css/semantic.min.css";
import { Input, Form, Header, Icon, Segment } from "semantic-ui-react";
import VideoViewer from "./components/video/VideoPlayer";
import { SubtitleProvider } from "./SubtitleContext";
import SubtitleCreate from "./components/subtitle/SubtitleCreate";
import SrtToMap from "./layout/DataDisplayArea";
import FileSaveMenu from "./components/FileSaveMenu";
import LocalstorageCheckModal from "./components/localstorage/LocalstorageCheck";

function App() {
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [fileAdd, setFileAdd] = useState(false);
  const youtubeUrlInput = useRef();

  const linkChange = (e) => {
    setYoutubeUrl(e.target.parentNode.firstElementChild.value);
  };

  return (
    <SubtitleProvider>
      <div className="App">
        <div className="topheader">
          {/* <Header size="large" floated="left">subtitle Editor</Header> */}
        <FileSaveMenu setFileAdd={setFileAdd}></FileSaveMenu>
        </div>
        <div className="App_wrapper">
          <div>
            <VideoViewer url={youtubeUrl}></VideoViewer>
            <Header size="large">동영상 및 자막 선택</Header>
            <Form>
              <Form.Field>
                <label>유튜브 영상 주소</label>
                <Input placeholder="youtube content ID를 입력하세요." icon>
                  <input ref={youtubeUrlInput} />
                  <Icon
                    name="search"
                    circular="true"
                    link={true}
                    onClick={linkChange}
                  ></Icon>
                </Input>
              </Form.Field>

              <SubtitleCreate
                fileAdd={fileAdd}
                setFileAdd={setFileAdd}
              ></SubtitleCreate>
            </Form>
            <Header size="large">파일 저장 및 출력</Header>
            
            <Header>사용방법</Header>
            <div>
              1. 작업하려는 유튜브 ID를 넣는다.(개인작업파일이라면 미등록으로
              업로드 후 링크를 가져온다.)
              <br />
              2. 자막 파일을 선택하여 추가 후 자막 등록하기를 누른다.(srt파일만
              지원)
              <br />
              3. 자막을 원하는 형태의 내용으로 변경한다.(ex. 영어 → 한국어)
              <br />
              4. 최종파일 출력하기를 눌러 srt 파일을 다운로드받는다.
              <br />
              <br />
              cf. 자막 입력 시 브라우저에 자동으로 데이터를 저장합니다. 새로고침
              시 지금까지 작업했던 내용을 불러올 수 있습니다.
              <br />
              <br />
              cf2. 시간을 누르면 영상이 해당 시간으로 이동합니다.
            </div>
            <div className="ad">
              ver. 2021.03.02.0001
              <br />
              For developer: mrchoi@llit.kr / <a href="https://coupa.ng/bR2xmd">쿠팡</a> (링크를
              통해 구매 시 제작자에게 수익이 발생함)
            </div>
          </div>
          <div className="subtitle_wrap">
            <SrtToMap youtubeUrl={youtubeUrl} />
          </div>
        </div>
      </div>
      <LocalstorageCheckModal setFileAdd={setFileAdd}></LocalstorageCheckModal>
    </SubtitleProvider>
  );
}

export default App;
