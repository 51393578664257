import { useSubtitleDispatch, useSubtitleState } from "../SubtitleContext";
import { Button, Grid, Header } from "semantic-ui-react";
import React from "react";

function TimeFormatChange(startTime, endTime) {
  let startHour = parseInt(startTime / 3600);
  let startMin = parseInt((startTime % 3600) / 60);
  let startSec = parseInt((startTime % 3600) % 60);
  let startMill = parseInt((((startTime % 3600) % 60) - startSec) * 1000);

  startHour = startHour < 10 ? "0" + startHour : String(startHour);
  startMin = startMin < 10 ? "0" + startMin : String(startMin);
  startSec = startSec < 10 ? "0" + startSec : String(startSec);
  startMill =
    startMill < 10
      ? "00" + startMill
      : startMill < 100
      ? "0" + startMill
      : String(startMill);

  let endHour = parseInt(endTime / 3600);
  let endMin = parseInt((endTime % 3600) / 60);
  let endSec = parseInt((endTime % 3600) % 60);
  let endMill = parseInt((((endTime % 3600) % 60) - endSec) * 1000);

  endHour = endHour < 10 ? "0" + endHour : String(endHour);
  endMin = endMin < 10 ? "0" + endMin : String(endMin);
  endSec = endSec < 10 ? "0" + endSec : String(endSec);
  endMill =
    endMill < 10
      ? "00" + endMill
      : endMill < 100
      ? "0" + endMill
      : String(endMill);

  let time = `${startHour}:${startMin}:${startSec},${startMill} --> ${endHour}:${endMin}:${endSec},${endMill}`;
  return time;
}
const FileDownloadButton = () => {
  const subtitle = useSubtitleState();
  let text = "";
  const SrtDownload = () => {
    subtitle.map((e) => {
      text += `${e.id}
${TimeFormatChange(e.start, e.end)}\n`;

      e.korean.split("<br />").forEach((e) => (text += `${e}\n`));
      text += `\n`;
    });
    const element = document.createElement("a");
    const file = new Blob([text], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "test.srt";
    document.body.appendChild(element);
    element.click();
  };
  return (
    <Button color="blue" onClick={SrtDownload}>
      최종 파일 출력하기
    </Button>
  );
};

const FileSaveMenu = ({ setFileAdd }) => {
  const dispatch = useSubtitleDispatch();
  const sorry = () => {
    alert("작업중인 기능입니다.");
  };
  const deleteAll = () => {
    dispatch({
      type: "ERASE",
    });
    setFileAdd(false);
  };
  return (
    <>
      <Grid>
        <Grid.Row column={5} >
          <Grid.Column floated="left" width="5" verticalAlign="middle">
            <Header size="large">Subtitle Editor</Header>
            
          </Grid.Column>

          <Grid.Column
            floated="right"
             width="3"
             style={{ textAlign: "right" }}
          >
            <Button onClick={deleteAll}>내용 지우기</Button>
            {/* <Button onClick={sorry}>작업파일 다운로드</Button> */}
            <FileDownloadButton></FileDownloadButton>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default React.memo(FileSaveMenu);
